const clios2manufactureModule = {
    firestorePath: 'Clios2Manufacture',
    firestoreRefType: 'collection',
    moduleName: 'clios2manufactureModule',
    statePropName: 'data',
    namespaced: true,

    state: {},
    getters: {},
    mutations: {},
    actions: {},
  }

  export default clios2manufactureModule
