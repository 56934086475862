// ~store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

// import from step 1
import { Firebase, initFirebase } from '../config/firebase.js'
// import from step 3 (below)
import myModule from '../modules/myModule.js'


import clinicModule from '../modules/clinicModule.js'
import userModule from '../modules/userModule.js'
import addressModule from '../modules/addressModule.js'
import patientModule from '../modules/patientModule.js'
import prescriptionModule from '../modules/prescriptionModule.js'
import orderModule from '../modules/orderModule.js'
import paidModule from '../modules/paidModule.js'
import makerModule from '../modules/makerModule.js'
import manufactureModule from '../modules/manufactureModule.js'
import couponModule from '../modules/couponModule.js'
import commonModule from '../modules/commonModule.js'
import clios2manufactureModule from '../modules/clios2manufactureModule.js'
import cliosModule from '../modules/cliosModule.js'



// do the magic 🧙🏻‍♂️
const easyFirestore = VuexEasyFirestore(
  [
    myModule,
    patientModule,
    clinicModule,
    prescriptionModule,
    userModule,
    orderModule,
    paidModule,
    addressModule,
    makerModule,
    manufactureModule,
    couponModule,
    commonModule,
    cliosModule,
    clios2manufactureModule

  ],
  {logging: true, FirebaseDependency: Firebase}
)

// include as PLUGIN in your vuex store
// please note that "myModule" should ONLY be passed via the plugin
const storeData = {
  plugins: [easyFirestore, createPersistedState({storage: window.sessionStorage})],
  // ... your other store data
  state: {
    clinic: null,
    patient: null,
    authuser: {},
    status: false,
    order: {},
    merchandises:{
      l_merchandise: null,
      r_merchandise: null,
    }
  },
  mutations: {
    onAuthStateChanged(state, authuser) {
      state.authuser = authuser; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.status = status; //ログインしてるかどうか true or false
    }
  },
  getters: {
    user(state) {
      return state.authuser;
    },
    isSignedIn(state) {
      return state.status;
    },

  }
}

// initialise Vuex
const store = new Vuex.Store(storeData)

// initFirebase
initFirebase()
  .catch(error => {
    // take user to a page stating an error occurred
    // (might be a connection error, or the app is open in another tab)
    console.error("Error initFirebase:",error)
  })


export default store
