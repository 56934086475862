const manufactureModule = {
    firestorePath: 'Manufactures',
    firestoreRefType: 'collection',
    moduleName: 'manufactureModule',
    statePropName: 'data',
    namespaced: true,

    state: {},
    getters: {},
    mutations: {},
    actions: {},
  }

  export default manufactureModule
