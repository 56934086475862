const patientModule = {
    firestorePath: 'Patients',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'patientModule',
    statePropName: 'data',
    namespaced: true, // automatically added

    // this object is your store module (will be added as '/makerModule')
    // you can also add state/getters/mutations/actions
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  }

  export default patientModule
