const prescriptionModule = {
    firestorePath: 'Prescriptions',
    firestoreRefType: 'collection',
    moduleName: 'prescriptionModule',
    statePropName: 'data',
    namespaced: true,
  
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  }
  
  export default prescriptionModule